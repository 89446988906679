(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/list-group/views/table-row_partial.js') >= 0) return;  svs.modules.push('/components/ui/list-group/views/table-row_partial.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.ui=_svs.ui||{};
_svs.ui.list_group=_svs.ui.list_group||{};
_svs.ui.list_group.partials=_svs.ui.list_group.partials||{};
svs.ui.list_group.partials.table_row = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":71}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"list-group-item list-group-item-table"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":78}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"list-group-item-fluid\">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"leftContent") || (depth0 != null ? lookupProperty(depth0,"leftContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"leftContent","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"list-group-item-fixed\">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"rightContent") || (depth0 != null ? lookupProperty(depth0,"rightContent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rightContent","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n</li>";
},"useData":true});
Handlebars.partials['ui-list-group-table_row'] = svs.ui.list_group.partials.table_row;
})(svs, Handlebars);


 })(window);